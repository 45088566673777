<template>
  <div
    class="bgfooter bg-no-repeat bg-cover pt-28 pb-14 md:flex justify-around container-l"
  >
    <div
      class="md:flex-row flex flex-col items-center text-center mx-auto sm:mx-11 lborder-8"
    >
      <ul class="sm:flex">
        <li class="text-white mx-5 mb-4">
          <router-link to="/"> {{ $t("headings.home") }}</router-link>
        </li>

        <li class="text-white mx-5 mb-4">
          <router-link to="/about">{{ $t("headings.about") }}</router-link>
        </li>
        <li class="text-white mx-5 mb-4">
          <router-link to="/work ">{{ $t("headings.work") }}</router-link>
        </li>
        <li class="text-white mx-5 mb-4">
          <router-link to="/community">{{
            $t("headings.community")
          }}</router-link>
        </li>

        <li class="text-white mx-5 mb-4">
          <router-link to="/services">{{
            $t("headings.services")
          }}</router-link>
        </li>

        <li class="text-white mx-5 mb-4">
          <router-link to="/blog">{{ $t("headings.blog") }}</router-link>
        </li>
      </ul>
    </div>
    <ul
      class="justify-center flex flex-wrap text-center lborder-4 border-primary-600 md:mt-0 mt-6"
    >
      <li class="mb-4">
        <a href="https://www.facebook.com/varit.net" target="_blank">
          <font-awesome-icon
            :icon="['fab', 'facebook-f']"
            size="2x"
            class="mx-4 text-white"
          />
        </a>
      </li>
      <li class="mb-4">
        <a href="https://twitter.com/Varitnet" target="_blank">
          <font-awesome-icon
            :icon="['fab', 'twitter']"
            size="2x"
            class="mx-4 text-white"
          />
        </a>
      </li>
      <li class="mb-4">
        <a href="https://www.behance.net/varitnet" target="_blank">
          <font-awesome-icon
            :icon="['fab', 'behance']"
            size="2x"
            class="mx-4 text-white"
          />
        </a>
      </li>
      <li class="mb-4">
        <a href="https://www.linkedin.com/company/varit-net" target="_blank">
          <font-awesome-icon
            :icon="['fab', 'linkedin-in']"
            size="2x"
            class="mx-4 text-white"
          />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Footer"
};
</script>
