<template>
	<div>
		<div v-if="inMenu" class="flex">
			<div
				v-for="(lang, i) in langs"
				:key="`Lang${i}`"
				@click="$i18n.locale = lang"
				class="w-1/3 px-4"
			>
				<figure>
					<img v-if="lang === 'en'" src="@/assets/images/en.svg" alt="" />
					<img v-if="lang === 'ar'" src="@/assets/images/ar.svg" alt="" />
					<!-- <img
                        v-if="lang === 'de'"
                        src="@/assets/images/de.svg"
                        alt=""
                    /> -->
				</figure>
			</div>
		</div>
		<div v-else class="locale-changer">
			<select v-model="$i18n.locale">
				<option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{
					lang
				}}</option>
			</select>
		</div>
	</div>
</template>

<script>
export default {
	name: "locale-changer",
	props: ["inMenu"],
	data() {
		return { langs: ["en", "ar", "de"] };
		// module.exports = {
		// 	headings: {
		// 		home: "Homepage"
		// 	},

		// 	labels: {
		// 		name: "Name"
		// 	},

		// 	forms: {
		// 		email: "Email Address"
		// 	},

		// 	common: {
		// 		shared: "Shared Text everywhere"
		// 	},

		// 	misc: {
		// 		someText: "Lorem"
		// 	}
		// };
	}
};
</script>
