<template>
  <div class="container mx-auto flex">
    <div class="hidden md:grid main-header">
      <ul class="nav-container">
        <li>
          <router-link to="/" class="nav-item">{{
            $t("headings.home")
          }}</router-link>
        </li>
        <li>
          <router-link to="/about" class="nav-item">{{
            $t("headings.about")
          }}</router-link>
        </li>
        <li>
          <router-link to="/work" class="nav-item">{{
            $t("headings.work")
          }}</router-link>
        </li>
        <li>
          <router-link to="/community" class="nav-item">{{
            $t("headings.community")
          }}</router-link>
        </li>
        <li>
          <router-link to="/services" class="nav-item">{{
            $t("headings.services")
          }}</router-link>
        </li>
        <!-- <li>
          <router-link to="/blog" class="nav-item">{{
            $t("headings.blog")
          }}</router-link>
        </li> -->
        <!-- <li>
                    <switching-locales class="nav-item" />
                </li> -->
        <li>
          <button class="btn is-main nav-btn">
            <router-link to="/contact-us" class="">{{
              $t("headings.getInTouch")
            }}</router-link>
          </button>
        </li>
      </ul>
      <!-- <div>
				<button class="btn is-main nav-btn">
					<router-link to="/contact-us">Get In Touch</router-link>
				</button>
			</div> -->
    </div>
  </div>
</template>
<script>
// import SwitchingLocales from "./SwitchingLocales.vue";
export default {
  // components: { SwitchingLocales },
  name: "Navbar"
};
</script>
