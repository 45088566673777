<template>
    <div
        id="app"
        :class="{ 'is-rtl': $i18n.locale === 'ar' }"
    >
        <!-- <div id="nav"> -->
        <!-- <main-header class="hidden md:block" /> -->
        <side-menu />
        <navbar />
        <router-view class="screen mt-24 2xs:mt-32 md:mt-16" />
        <main-footer />
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import SideMenu from "@/components/SideMenu.vue";
import MainFooter from "@/components/MainFooter.vue";
// import Header from './components/Header.vue';
export default {
    components: {
        Navbar,
        SideMenu,
        MainFooter
    }
};
</script>
