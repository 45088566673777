import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("@/views/Home.vue")
	},
	{
		path: "/about",
		name: "About",
		component: () => import("@/views/About.vue")
	},
	{
		path: "/services",
		name: "Services",
		component: () => import("@/views/Services.vue")
	},
	{
		path: "/community",
		name: "Community",
		component: () => import("@/views/Community.vue")
	},
	{
		path: "/blog",
		name: "Blog",
		component: () => import("@/views/Blog.vue")
	},
	{
		path: "/contact-us",
		name: "Contact Us",
		component: () => import("@/views/ContactUs.vue")
	},

	// {
	// 	path: "/project",
	// 	name: "Project",
	// 	component: () => import("@/views/Project.vue")
	// },
	{
		path: "/gabal-equepment",
		name: "Gabal Equepment",
		component: () => import("@/views/GabalEquepment.vue")
	},
	{
		path: "/7fza",
		name: "7fza",
		component: () => import("@/views/7fza.vue")
	},
	{
		path: "/eletihad-factory",
		name: "eletihad factory",
		component: () => import("@/views/EletihadFactory.vue")
	},
	{
		path: "/alf-hana",
		name: "Alf Hana",
		component: () => import("@/views/AlfHana.vue")
	},
	{
		path: "/work",
		name: "Work",
		component: () => import("@/views/Work.vue")
		// children: [
		// 	{
		// 		path: "",
		// 		name: "AllWork",
		// 		component: () => import("@/components/WorkSection.vue"),
		// 		props: { all: true }
		// 	},
		// 	{
		// 		path: "",
		// 		name: "WebSites",
		// 		component: () => import("@/components/WorkSection.vue"),
		// 		props: { web: true }
		// 	},
		// 	{
		// 		path: "",
		// 		name: "PhoneApps",
		// 		component: () => import("@/components/WorkSection.vue"),
		// 		props: { phone: true }
		// 	},
		// 	{
		// 		path: "",
		// 		name: "OtherWork",
		// 		component: () => import("@/components/WorkSection.vue"),
		// 		props: { other: true }
		// 	}
		// ]
	},
	{
		path: "/blog/:slug",
		name: "Article",
		component: () => import("@/views/Article.vue")
	},

	{
		path: "/job",
		name: "Job",
		component: () => import("@/views/Job.vue")
	},
	{
		path: "/services/:slug",
		name: "Service",
		component: () => import("@/views/Service.vue")
	},
	{
		path: "/web-service",
		name: "Web Service",
		component: () => import("@/views/WebService.vue")
	},
	{
		path: "/mobile-service",
		name: "Mobile Service",
		component: () => import("@/views/MobileService.vue")
	},
	{
		path: "/design-service",
		name: "Design Service",
		component: () => import("@/views/DesignService.vue")
	},
	{
		path: "/hosting-service",
		name: "Hosting Service",
		component: () => import("@/views/HostingService.vue")
	},
	{
		path: "/marketing-service",
		name: "Marketing Service",
		component: () => import("@/views/MarketingService.vue")
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	}
});

export default router;
