<template>
	<div>
		<div
			class="side-menu"
			:class="isOpen ? 'side-menu-open' : 'side-menu'"
			v-click-outside="hide"
		>
			<!-- @focus="isOpen = true" -->
			<!-- @focusout="isOpen = false" -->
			<!-- tabindex="0" -->
			<!-- @focuswithin="isOpen = false" -->
			<div class="side-menu-bar">
				<div class="logo">
					<router-link to="/">
						<figure class="logo-figure">
							<img
								class="logo-img w-full h-full object-contain"
								src="@/assets/images/logo.svg"
								alt=""
							/>
						</figure>
					</router-link>
				</div>
				<div class="side-menu-bar-controll" @click="isOpen = !isOpen">
					<figure class="m-auto">
						<img
							v-if="isOpen"
							src="@/assets/images/menu-close.svg"
							class="controll-icon close-icon"
						/>
						<img
							v-else
							src="@/assets/images/menu-open.svg"
							class="controll-icon"
						/>
					</figure>
				</div>
				<!-- <div class="dark-light-controll">
					<span class="controll-icon"> dark </span>
				</div> -->
			</div>
			<div
				class="side-menu-items"
				:class="isOpen ? 'side-menu-items-open' : 'side-menu-items'"
				@click="isOpen = false"
			>
				<ul class="side-nav-container">
					<li class="side-nav-item">
						<router-link to="/" class="side-nav-item-a">{{
							$t("headings.home")
						}}</router-link>
					</li>
					<li class="side-nav-item">
						<router-link to="/about" class="side-nav-item-a">{{
							$t("headings.about")
						}}</router-link>
					</li>
					<li class="side-nav-item">
						<router-link to="/work" class="side-nav-item-a">{{
							$t("headings.work")
						}}</router-link>
					</li>
					<li class="side-nav-item">
						<router-link to="/community" class="side-nav-item-a">{{
							$t("headings.community")
						}}</router-link>
					</li>
					<li class="side-nav-item">
						<router-link to="/services" class="side-nav-item-a">{{
							$t("headings.services")
						}}</router-link>
					</li>
					<li class="side-nav-item">
						<router-link to="/blog" class="side-nav-item-a">{{
							$t("headings.blog")
						}}</router-link>
					</li>
					<li class="side-nav-item" v-if="false">
						<switching-locales :in-menu="true" />
					</li>
					<li>
						<button class="btn is-main nav-btn mb-4 mt-8 whitespace-nowrap">
							<router-link to="/contact-us" class=" text-base">{{
								$t("headings.getInTouch")
							}}</router-link>
						</button>
					</li>
				</ul>
				<div class="" @click="isOpen = false">
					<!-- <router-link to="/" class="side-nav-item">Home</router-link>
          <router-link to="/about" class="side-nav-item">About</router-link>
          <router-link to="/work" class="side-nav-item">Work</router-link>
          <router-link to="/community" class="side-nav-item"
            >Community</router-link
          >
          <router-link to="/services" class="side-nav-item"
            >Services</router-link
          >
          <router-link to="/blog" class="side-nav-item">Blog</router-link> -->
				</div>
				<div class="self-center hidden lg:block">
					<figure class="menu-grafic">
						<img
							class="w-full h-full object-contain"
							src="@/assets/images/side-menu-grafic.svg"
							alt=""
						/>
					</figure>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SwitchingLocales from "./SwitchingLocales.vue";
export default {
	directives: {
		clickOutside: {
			bind: function(el, binding, vnode) {
				el.clickOutsideEvent = function(event) {
					if (!(el == event.target || el.contains(event.target))) {
						vnode.context[binding.expression](event);
					}
				};
				document.body.addEventListener("click", el.clickOutsideEvent);
			},
			unbind: function(el) {
				document.body.removeEventListener("click", el.clickOutsideEvent);
			}
		}
	},
	components: { SwitchingLocales },
	name: "SideMenu",
	data() {
		return {
			isOpen: false
		};
	},
	methods: {
		hide() {
			this.isOpen = false;
		}
	}
};
</script>
